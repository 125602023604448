<template>
  <div class="fixed inset-0 z-20 flex flex-col px-8 py-12 top-16 bg-trublue-50 space-y-9">
    <div class="flex justify-between">
      <h3 class="font-semibold">{{ t('searchAndFilterOptions') }}</h3>
      <button @click="close" class="flex items-center justify-center">
        <font-awesome-icon icon="fa solid fa-x" class="text-neutral-700" />
      </button>
    </div>
    <form @submit.prevent="submitForm">
      <div class="space-y-4">
        <div class="pl-4 bg-white border border-gray-300 rounded-3xl">
          <HeaderSearchFormStoreTypes />
        </div>
        <div class="px-4 py-1 bg-white border border-gray-300 rounded-3xl">
          <HeaderSearchFormStore />
        </div>
        <div class="px-4 py-1 bg-white border border-gray-300 rounded-3xl">
          <HeaderSearchFormLocation />
        </div>
      </div>
      <UButton block class="py-3 mt-8 text-sm text-white bg-trublue-700 rounded-3xl" @click="submitForm">
        {{ t('findStores') }}
      </UButton>
      <button @click="cancel" class="w-full mt-12 text-xs font-bold text-trublue-700">{{ t('cancel') }}</button>
    </form>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const search = useSearchStore()
const map = useMapStore()
const ui = useUiStore()

const currentZoom = map.zoom
const currentCenter = [...map.center]
const currentSearchQueryState = { ...search.query }

const emit = defineEmits<{
  close: []
}>()

function cancel() {
  // reset form data in the search store
  search.storeTypeIds = currentSearchQueryState.storeTypeIds
  search.storeOrOutlet = undefined
  search.location = undefined

  // todo: improve this by setting a prop on the location so it doesn't jump
  // set back to previous locations
  map.zoom = currentZoom
  map.navigateTo = currentCenter
  close()
}

function close() {
  ui.hideFilterOptionsDialog()
}

async function submitForm() {
  await search.performSearch()
  close()
}
</script>
