<template>
  <div class="flex flex-col h-screen max-h-full antialiased bg-white">
    <Header />
    <main class="flex h-full overflow-hidden md:flex-row">
      <StoreList />
      <div class="relative w-full h-full">
        <OpenLayersMap />
        <UiOnboarding />
      </div>
    </main>
    <Footer />
    <CookieConsent />
  </div>
</template>
<script setup lang="ts">
</script>
