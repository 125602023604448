import revive_payload_client_4sVQNw7RlN from "/application/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/application/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CaKIoANnI2 from "/application/node_modules/nuxt/dist/app/plugins/router.js";
import payload_client_yVLowv6hDl from "/application/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/application/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/application/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/application/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/application/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/application/.nuxt/components.plugin.mjs";
import plugin_client_LcKgStRyi6 from "/application/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_tbFNToZNim from "/application/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import primevue_plugin_egKpok8Auk from "/application/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/application/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_ghbUAjaD3n from "/application/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import slideovers_X7whXyrIWR from "/application/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/application/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/application/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/application/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/application/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/application/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/application/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _01_vue_query_k8pj2QgNCo from "/application/plugins/01.vue-query.ts";
import _02_boot_rHbmGvvBWF from "/application/plugins/02.boot.ts";
import fontawesome_cn2c4tOOHz from "/application/plugins/fontawesome.ts";
import sentry_client_shVUlIjFLk from "/application/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_CaKIoANnI2,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  plugin_client_LcKgStRyi6,
  plugin_tbFNToZNim,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  plugin_ghbUAjaD3n,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  _01_vue_query_k8pj2QgNCo,
  _02_boot_rHbmGvvBWF,
  fontawesome_cn2c4tOOHz,
  sentry_client_shVUlIjFLk
]