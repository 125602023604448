<template>
  <nav
    :class="{
      'w-full visible shrink-0 md:w-sidebar md:mr-2': ui.showStoreList,
      'invisible w-0 mr-0': !ui.showStoreList
    }"
    class="relative h-full min-w-0 overflow-y-hidden [&>div]:last:[&>div]:border-b-0 mt-2 md:transform-gpu md:transition-all md:duration-500 z-10"
  >
    <div v-if="search.pending">
      <div v-for="n in 6" :key="n" class="px-8 pt-6 pb-4 space-y-2 border-b border-gray-300">
        <USkeleton class="h-4 w-[150px]" />
        <div class="flex justify-between">
          <USkeleton class="h-4 w-[120px]" />
          <USkeleton class="h-4 w-[60px]" />
        </div>
        <USkeleton class="h-5 w-[150px]" />
        <div class="flex justify-between gap-x-16">
          <USkeleton class="h-4 w-[150px]" />
          <USkeleton class="h-7 w-[75px] relative bottom-2" />
        </div>
      </div>
    </div>
    <VirtualScroller
      v-if="search.stores.length > 0"
      :items="search.stores"
      :itemSize="190"
      style="height: 100%"
      class="w-full"
    >
      <template v-slot:item="{ item, options }">
        <StoreListCard
          :result="item"
          :showDistance="user.useMyLocation"
          :selected="item.id === map.selectedFeatureId"
          :key="item.id"
          @click="onStoreClicked(item.id)"
        />
      </template>
    </VirtualScroller>

    <div v-if="search.stores.length === 0 && !search.pending" class="p-8 pt-6 text-purple">
      <h3 class="font-semibold">{{ t('noMatchingResults') }}</h3>
      <div class="mt-8 text-sm">{{ t('maybeYouCouldTry') }}</div>
      <ul class="mt-4 text-sm list-disc list-inside">
        <li>{{ t('doubleCheckingYourSpelling') }}</li>
        <li>{{ t('choosingADifferentLocation') }}</li>
        <li>{{ t('amendingYourChoiceOfStoreType') }}</li>
      </ul>
    </div>
    <!-- <div
      class="sticky bottom-0 flex items-center justify-between h-20 px-8 py-4 bg-white border-t border-gray-300"
    >
      <button
        class="px-3 py-1 text-xs font-bold border-2 text-trublue-700 border-trublue-700 rounded-3xl"
      >
        Provide feedback
        <font-awesome-icon icon="fa-solid fa-chevron-right ml-1" />
      </button>
      <div class="text-xs text-gray-500">&copy; TruRating</div>
    </div> -->
  </nav>
</template>

<script setup lang="ts">
import VirtualScroller from 'primevue/virtualscroller'

const { t } = useI18n()
const map = useMapStore()
const search = useSearchStore()
const ui = useUiStore()
const user = useUserStore()
const gtm = useGtm()

function onStoreClicked(storeId: number) {
  const store = search.getStoreById(storeId)
  map.selectedFeatureId = store.id
  ui.toggleResultsView()

  gtm?.trackEvent({
    event: 'Store List Selected',
    category: 'discovery',
    action: 'User selected store from Store List',
    label: store.displayName
  })
}
</script>
