<template>
  <div
    id="map-popover"
    class="overflow-hidden absolute w-full min-w-[250px] max-h-max max-w-sm pb-3 px-3 md:px-0 md:p-0 bottom-0 md:bottom-auto left-1/2 -translate-x-1/2 md:translate-x-0 md:w-96 md:top-8 md:left-8"
  >
    <UCard
      v-if="store"
      :ui="{
        strategy: 'override',
        base: 'border shadow-lg',
        ring: '',
        divide: '',
        header: {
          padding: 'p-4 sm:p-4'
        },
        body: {
          padding: 'px-4 py-0 sm:px-4 pb-4 sm:py-0 sm:pb-4'
        },
        footer: {
          base: 'border-t-2 border-trublue-500',
          padding: 'py-4 px-4 sm:px-4'
        }
      }"
    >
      <template #header>
        <div class="flex justify-between">
          <h3 class="font-bold md:text-lg">
            {{ store.displayName }}
          </h3>
          <UButton
            variant="ghost"
            class="text-gray-500"
            :title="`Close popover for ${store.displayName}`"
            @click="emit('close')"
          >
            <font-awesome-icon icon="fa-solid fa-x" />
          </UButton>
        </div>
        <h4 class="mt-2 text-sm font-semibold">
          {{ addressConcatenated }}
        </h4>
        <!-- <p class="mt-2 text-sm text-gray-500">
          {{ addressConcatenated }}
        </p> -->
      </template>
      <div class="flex justify-between">
        <div class="flex items-center space-x-2 text-xs text-gray-500">
          <span v-for="badgeText in store.badges">{{ badgeText }}</span>
        </div>
        <div class="flex items-center justify-end w-24 text-sm font-semibold shrink-0 text-purple">
          <img src="@/assets/images/map-pin.svg" alt="Map Pin" class="w-5 h-5 mr-2" />
          <span>{{ $n(store.distance, 'decimal') }} km</span>
        </div>
      </div>
      <template #footer>
        <div class="flex gap-x-2">
          <img
            src="@/assets/images/trubutton.svg"
            alt="trubutton"
            width="56"
            height="56"
            class="relative self-start w-12 h-12 md:w-14 md:h-14 -top-1"
          />
          <div class="flex flex-wrap items-center justify-between w-full">
            <UiStarRatings
              v-if="store.truRating && store.ratingCount"
              :rating="parseFloat(store.truRating)"
              :total-ratings="store.ratingCount"
              :size="isDesktop ? 'regular' : 'small'"
            />
            <span v-else>No ratings available</span>
            <UButton
              v-if="store.profileUrl"
              :to="store.profileUrl"
              target="_blank"
              variant="outline"
              size="xs"
              :ui="{
                rounded: 'rounded-2xl',
                font: 'font-bold'
              }"
              class="mt-4 text-trublue-700"
            >
              {{t('more')}}
              <template #trailing>
                <font-awesome-icon icon="fa-solid fa-chevron-right" class="text-trublue-700" />
              </template>
            </UButton>
          </div>
        </div>
      </template>
    </UCard>
  </div>
</template>

<script setup lang="ts">
import type { StoreResult } from '@/types'

const { t } = useI18n()
const props = defineProps<{ store: StoreResult | undefined }>()
const emit = defineEmits<{ close: [] }>()
const { isDesktop } = useDevice()

let addressConcatenated = ''
if (props.store) {
  const { town, county, postCode, distance } = props.store
  addressConcatenated = [town, county, postCode].filter(Boolean).join(', ')
}
</script>
