import { useQuery } from '@tanstack/vue-query'
import { defineStore, acceptHMRUpdate } from 'pinia'
import type { StoreType } from '~/types'

export const useStoreTypesStore = defineStore('storetypes', () => {
  const config = useRuntimeConfig()
  const { locale } = useI18n()
  const { data } = useQuery({
    queryKey: ['storetypes', 'list'],
    queryFn: () =>
      $fetch<Array<StoreType>>(`${config.public.api.profiles.baseUrl}/storetypes?cultureCode=${locale.value}`),
    staleTime: Infinity
  })

  return {
    // state
    storeTypes: computed(() => data.value ?? [])
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStoreTypesStore, import.meta.hot))
}
