import type { MapCoordinate, ResultsView } from '~/types'
import { acceptHMRUpdate } from 'pinia'

// all in one file for now, will be split into multiple files later if necessary
export const useUiStore = defineStore('ui', () => {
  const config = useRuntimeConfig()
  const { isMobile, isMobileOrTablet } = useDevice()

  // state
  const showList = ref(!isMobile)
  const resultsView = ref<ResultsView>(isMobile ? 'map' : 'list-map')
  const showResultsAsMapMoves = ref(true)
  const searchAndFilterOptionsDialogVisible = ref(false)

  // getters
  const showStoreList = computed(() => {
    return showList.value && (resultsView.value === 'list-map' || resultsView.value === 'list')
  })

  const showMap = computed(() => {
    return resultsView.value === 'list-map' || resultsView.value === 'map'
  })

  // actions
  function showFilterOptionsDialog() {
    searchAndFilterOptionsDialogVisible.value = true
  }

  function hideFilterOptionsDialog() {
    searchAndFilterOptionsDialogVisible.value = false
  }

  function toggleResultsView() {
    if (isMobile) {
      showList.value = !showList.value
      resultsView.value = resultsView.value === 'list' ? 'map' : 'list'
    } else resultsView.value = 'list-map'
  }

  function showMapView() {
    resultsView.value = 'map'
    showList.value = false
  }

  return {
    // state
    resultsView,
    showList,
    showResultsAsMapMoves,
    searchAndFilterOptionsDialogVisible,
    
    // getters
    showStoreList,
    showMap,

    // actions
    hideFilterOptionsDialog,
    showFilterOptionsDialog,
    showMapView,
    toggleResultsView
  }
})

// adds HMR for pinia
if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useUiStore, import.meta.hot))
