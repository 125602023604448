<template>
  <div class="relative flex items-center justify-between h-full">
    <AutoComplete
      v-model="search.storeOrOutletSearchModel"
      :suggestions="storesOrOutletSearch.results"
      :delay="300"
      :virtual-scroller-options="{ itemSize: 40 }"
      :loading="storesOrOutletSearch.pending"
      :scroll-height="scrollHeight"
      :min-length="2"
      @complete="$event => performSearch($event.query)"
      @item-select="onStoreOrOutletsItemSelect"
      :placeholder="t('storeOrOutletName')"
      option-group-label="country"
      option-group-children="stores"
      option-label="name"
      option-value="id"
      :pt="pt"
    >
      <template #loadingicon>
        <div class="px-3">
          <div class="loader"></div>
        </div>
      </template>
    </AutoComplete>
    <UButton
      v-show="showClearButton"
      variant="ghost"
      class="absolute right-0 -mt-4 text-gray-500 top-1/2"
      @click="clear"
      >X</UButton
    >
  </div>
</template>

<script setup lang="ts">
import type { StoreOrOutlet } from '~/types'
import type { AutoCompleteItemSelectEvent, AutoCompletePassThroughOptions } from 'primevue/autocomplete'
import type { PassThrough } from 'primevue/ts-helpers'
const { isSafari } = useDevice()
const { t } = useI18n()

const config = useRuntimeConfig()
const map = useMapStore()
const search = useSearchStore()
const storesOrOutletSearch = useStoresOrOutletsStore()
const gtm = useGtm()

function onStoreOrOutletsItemSelect({ value }: AutoCompleteItemSelectEvent) {
  const storeOrOutlet = value as StoreOrOutlet
  search.storeOrOutlet = storeOrOutlet

  if (!search.storeOrOutlet.isBrand) {
    map.selectedFeatureId = storeOrOutlet.id
    map.navigateTo = [storeOrOutlet.long, storeOrOutlet.lat]
    map.zoom = config.public.map.storeZoomLevel
  } else {
    map.navigateTo = [storeOrOutlet.long, storeOrOutlet.lat]
    map.zoom = config.public.map.stateZoomLevel
  }
}

function performSearch(locationOrPostalCode: string) {
  if (locationOrPostalCode.length < 2) return
  gtm?.trackEvent({
    event: 'Search by Store or Outlet',
    category: 'search',
    action: 'User searched by Store or Outlet',
    label: locationOrPostalCode
  })

  storesOrOutletSearch.search(locationOrPostalCode)
}

function clear() {
  search.storeOrOutletSearchModel = undefined
  search.storeOrOutlet = undefined
}

const showClearButton = computed(() => {
  if (!storesOrOutletSearch.searchTerm) return false
  return storesOrOutletSearch.searchTerm && storesOrOutletSearch.status === 'success'
})

const scrollHeight = computed(() => (storesOrOutletSearch.results.length === 0 ? '50px' : '200px'))

const pt = computed<PassThrough<AutoCompletePassThroughOptions>>(() => {
  return {
    root: {
      class: 'w-full flex justify-between items-center'
    },
    container: {
      class: 'list-none cursor-text'
    },
    input: {
      class: [
        'h-full text-sm text-gray-500 w-full py-1.5 placeholder-gray-500 bg-transparent outline-none selection:bg-trublue',
        isSafari ? 'text-base' : 'text-sm'
      ]
    },
    panel: {
      class: 'bg-white max-h-[250px] pt-1 overflow-auto rounded-b-lg shadow-xl md:!min-w-[430px]'
    },
    list: {
      class: 'py-3 list-none m-0'
    },
    item: {
      class:
        'px-4 py-2 flex text-gray-500 justify-between cursor-pointer hover:bg-trublue-50 text-sm overflow-hidden relative whitespace-nowrap m-0'
    },
    itemgroup: {
      class: ['m-0 px-4 pt-2 border-gray-300 text-trublue-700 bg-white font-semibold text-sm', 'cursor-auto']
    },
    emptyMessage: {
      class: 'px-4 text-sm text-trublue-700 font-semibold'
    }
    // virtualScroller: {
    //   root: {
    //     class: storesOrOutletSearch.results.length === 0 ? '!max-h-[50px]' : ''
    //   }
    //   // style: 'max-height: 25px;'
    //   // class: storesOrOutletSearch.results.length === 0 ? '!max-h-[40px]' : ''
    // }
  }
})
</script>
