<template>
  <div class="relative flex items-center justify-between h-full">
    <AutoComplete
      ref="locationAutoComplete"
      v-model="geocoded.searchTermModel"
      :suggestions="getSuggestions()"
      :delay="300"
      :loading="geocoded.pending"
      :min-length="2"
      :placeholder="isMobile ? t('location') : t('locationOrPostalCode')"
      @complete="$event => performSearch($event.query)"
      @item-select="onSelected"
      @focus="onFocus"
      @blur="autocompleteHasFocus = true"
      option-label="value"
      option-value="id"
      dropdown
      :pt="pt"
    >
      <template #header>
        <UButton
          v-if="user.permissionStatus == 'denied'"
          block
          variant="ghost"
          class="justify-start pt-4 pb-2 font-semibold text-pink-700 bg-pink-100"
          @click="showText = !showText"
        >
          <div
            v-if="showText && user.permissionStatus === 'denied'"
            class="absolute -ml-4 lg:ml-0 w-[calc(100%+5rem)] lg:w-[calc(100%)] text-sm top-10 z-50"
          >
            <div class="fixed flex-col font-medium text-justify translate-x-4 top-15 right-20 text-purple">
              <div class="flex flex-col p-4 bg-white border border-gray-300 shadow-md max-w-44 lg:max-w-96">
                {{ t('unableToUseYourLocation') }}
              </div>
            </div>
          </div>
          <template #leading>
            <Icon name="mdi:navigation-variant-outline" :aria-multiline="t('useMyLocation')" size="20px" />
          </template>
          <div class="flex justify-between w-full">
            <div class="p-1">{{ t('useMyLocation') }}</div>
            <div class="p-1 font-light text-white bg-red-500 rounded-full">{{ t('unavailable') }}</div>
          </div>
        </UButton>
        <UButton
          v-if="user.permissionStatus !== 'denied'"
          block
          variant="ghost"
          class="justify-start pt-4 pb-2 font-semibold text-trublue-700"
          @click="goToUserLocation"
        >
          <template #leading>
            <Icon name="mdi:navigation-variant-outline" :aria-multiline="t('useMyLocation')" size="20px" />
          </template>
          {{ t('useMyLocation') }}</UButton
        >
      </template>
      <template #loadingicon>
        <div v-show="geocoded.pending" class="loader"></div>
      </template>
    </AutoComplete>
    <UButton
      v-show="user.useMyLocation || (geocoded.searchTermModel && !geocoded.pending)"
      variant="ghost"
      class="absolute right-0 z-20 hidden -mt-4 text-gray-500 md:block top-1/2"
      @click="clear"
      >X</UButton
    >
  </div>
</template>

<script setup lang="ts">
import type { AutoCompleteItemSelectEvent, AutoCompletePassThroughOptions } from 'primevue/autocomplete'
import type { PassThrough } from 'primevue/ts-helpers'
import type { GeoCoderSearchResult } from '~/types'

const { t } = useI18n()
const { isMobile } = useDevice()

const locationAutoComplete = ref()
const autocompleteHasFocus = ref(false)

const config = useRuntimeConfig()
const map = useMapStore()
const geocoded = useGeocodedStore()
const search = useSearchStore()
const ui = useUiStore()
const user = useUserStore()
const gtm = useGtm()
const gtag = useGtag()
const showText = ref(false)

function onFocus() {
  locationAutoComplete.value.show()
  autocompleteHasFocus.value = true
  if (search.location) {
    clear()
  }
}

async function performSearch(locationOrPostalCode: string) {
  if (locationOrPostalCode.length < 2) return
  gtm?.trackEvent({
    event: 'Search by location',
    category: 'search',

    action: 'User searched by location',
    label: locationOrPostalCode
  })
  geocoded.fetchSuggestions(locationOrPostalCode)
}

function clear() {
  geocoded.clear()
  locationAutoComplete.value.show(true)
}

async function onSelected({ value }: AutoCompleteItemSelectEvent) {
  const location = value as GeoCoderSearchResult
  await geocoded.fetchGeocodedPlace(location.id)

  if (geocoded.geocodedPlace) {
    user.pauseGeoLocation()
    locationAutoComplete.value.hide()
    search.location = geocoded.geocodedPlace
    // calculate zoom level based on the address components
    const zoomLevel = geocoded.calculateZoomLevel(geocoded.geocodedPlace.addressComponents, map.zoom)
    map.navigateTo = [geocoded.geocodedPlace.longitude, geocoded.geocodedPlace.latitude]
    map.zoom = zoomLevel
  }
  // if mobile, show the map
  if (isMobile) {
    ui.showMapView()
  }
}

async function goToUserLocation() {
  await user.fetchGeoLocation()
  locationAutoComplete.value.hide()
  map.navigateTo = user.coordinates
  map.zoom = config.public.map.userPositionZoomLevel
}

function getSuggestions() {
  return geocoded.suggestions
}

const pt = computed<PassThrough<AutoCompletePassThroughOptions>>(() => {
  return {
    root: {
      class: 'w-full flex justify-between items-center'
    },
    container: {
      class: 'list-none cursor-text'
    },
    input: {
      class:
        'h-full text-sm text-gray-500 w-full py-1.5 placeholder-gray-500 bg-transparent outline-none selection:bg-trublue'
    },
    panel: ({ props }) => {
      return {
        class: [
          'bg-white max-h-[250px] pt-1 overflow-auto rounded-b-lg shadow-xl',
          props.suggestions?.length === 0 ? 'pb-0' : 'pb-4',
          isMobile ? 'w-9/12' : ''
        ]
      }
    },
    list: ({ props }) => {
      return {
        class: props.suggestions?.length === 0 ? 'hidden' : 'list-none m-0 max-w-[400px]'
      }
    },
    item: {
      class:
        'px-4 py-2 text-gray-500 cursor-pointer hover:bg-trublue-50 text-sm overflow-hidden text-ellipsis relative whitespace-nowrap m-0 truncate'
    },
    itemgroup: {
      class: ['m-0 px-4 pt-2 border-t border-gray-300 text-trublue-700 bg-white font-semibold text-sm', 'cursor-auto']
    },
    virtualScroller: {},
    emptyMessage: {
      class: 'px-4 text-sm text-trublue-700 font-semibold'
    },
    dropdownButton: {
      root: {
        class: 'w-full absolute inset-0 h-full bg-transparent [&>svg]:hidden'
      }
    }
  }
})
</script>
