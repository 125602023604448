<template>
  <UCard
    :ui="{
      strategy: 'override',
      background: selected ? 'bg-trublue-50' : 'bg-white',
      ring: '',
      shadow: '',
      header: {
        padding: 'pl-8 pr-6 py-4 sm:pl-8 sm:pr-6'
      },
      body: {
        padding: 'pl-8 pr-6 py-0 sm:py-0 sm:pl-8 sm:pr-6'
      },
      footer: {
        padding: 'pl-8 pr-6 py-4 sm:pl-8 sm:pr-6',
        base: 'border-b border-gray-300'
      },
      divide: ''
    }"
  >
    <template #header>
      <h3 class="font-semibold">{{ result.displayName }}</h3>
      <div class="flex items-center justify-between mt-2 text-sm gap-x-2">
        <div class="text-gray-500">
          <p>{{ addressConcatenated }}</p>
        </div>
        <div
          v-if="showDistance"
          class="flex items-center self-start justify-end w-24 text-sm font-semibold shrink-0 text-purple gap-x-1"
        >
          <img src="@/assets/images/map-pin.svg" alt="Map Pin" class="w-5 h-5" />
          <span>{{ distanceText }} km</span>
        </div>
      </div>
    </template>
    <UiStarRatings
      v-if="result.truRating && result.ratingCount"
      :rating="parseFloat(result.truRating)"
      :total-ratings="result.ratingCount"
      variant="purple"
    />
    <template #footer>
      <div class="flex justify-between">
        <div class="flex items-center space-x-2 text-xs text-gray-500">
          <span v-for="badgeText in result.badges">{{ badgeText }}</span>
        </div>
        <UButton
          v-if="result.profileUrl"
          :to="result.profileUrl"
          target="_blank"
          variant="outline"
          size="xs"
          :ui="{
            rounded: 'rounded-2xl',
            font: 'font-bold'
          }"
          class="text-trublue-700"
        >
          {{t('more')}}
          <template #trailing>
            <font-awesome-icon icon="fa-solid fa-chevron-right" class="text-trublue-700" />
          </template>
        </UButton>
      </div>
    </template>
  </UCard>
</template>

<script setup lang="ts">
import type { StoreResult } from '@/types'

const { t } = useI18n()
const props = defineProps<{ result: StoreResult; showDistance: boolean; selected: boolean }>()

const { town, county, postCode, distance } = props.result
const addressConcatenated = [town, county, postCode].filter(Boolean).join(', ')

const distanceText = computed(() => {
  if (isNaN(distance) || distance === undefined) return ''
  return distance < 10 ? n(distance, 'decimal') : distance.toFixed(0)
})
</script>
